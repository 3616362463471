<template>
  <NLayout>
    <NLayoutContent>
      <template v-if="!registered">
        <RegForm
          ref="regform"
          v-on:onSuccess="handleSuccess"
          v-on:onError="handleError"
        ></RegForm>
      </template>
      <template v-if="registered && isSuccess">
        <div
          class="border border-slate-200 flex justify-center items-center p-0 m-0 text-custom-purple font-bold -mb-4 mt-6 text-xl"
        >
          Предрегистрация завершена!
          <!-- <br /> -->
          <!-- Ваша скидка составила <b>{{ discount }}</b> р. -->
        </div>
      </template>
      <template v-if="registered && isError">
        <div>
          Произошла ошибка. Попробуйте повторить попытку регистрации позже
        </div>
      </template>
    </NLayoutContent>
  </NLayout>
</template>

<script>
import RegForm from "@/components/RegForm";
import "vfonts/Lato.css";
// Monospace Font
import "vfonts/FiraCode.css";
import { NLayout, NLayoutContent } from "naive-ui";

export default {
  name: "App",
  components: {
    RegForm,
    NLayout,
    NLayoutContent,
  },
  data() {
    return {
      registered: false,
      isSuccess: false,
      isError: false,
      discount: 0.0,
    };
  },
  mounted() {
    //let tgscr = document.createElement('script')
    //tgscr.setAttribute('src', 'https://telegram.org/js/telegram-web-app.js')
    //document.head.appendChild(tgscr)
    window.Telegram.WebApp.MainButton.text = "Зарегистрировать";
    window.Telegram.WebApp.ready();
    console.log("ready");
    window.Telegram.WebApp.MainButton.show();
    window.isRegistered = false;
    window.Telegram.WebApp.onEvent("mainButtonClicked", () => {
      // window.myApp._component.components.RegForm.methods.register();
      if (!window.isRegistered) {
        window.document.querySelector("*[data-submit-button]").click();
      } else {
        window.Telegram.WebApp.close();
      }
    });
  },
  methods: {
    handleSuccess(response) {
      console.log("success!");
      this.discount = response.final_discount;
      window.isRegistered = true;
      this.isSuccess = true;
      window.Telegram.WebApp.MainButton.text = "Закрыть";
      this.registered = true;
    },
    handleError() {
      console.log("error!");
      window.isRegistered = true;
      this.isError = true;
      window.Telegram.WebApp.MainButton.text = "Закрыть";
      this.registered = true;
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
