<template>
  <div class="input-form" :v-if="list.length > 0">
    <label class="n-form-item-label"
      >{{ title }}
      <template v-if="required">
        <span class="n-form-item-label__asterisk">&nbsp;*</span>
      </template>
    </label>
    <div class="description">
      {{ description }}
    </div>
    <NFormItem :path="path">
      <NSpace style="width: 100%">
        <NRadioGroup>
          <template
            v-for="item in list"
            :key="item.key ? item.key : item.value"
          >
            <NRadio
              :checked="value == item.value"
              :value="item.value"
              :name="name"
              @change="handleChange"
              >{{ item.description ? item.description : item.value }}
            </NRadio>
          </template>
        </NRadioGroup>
      </NSpace>
    </NFormItem>
  </div>
</template>

<script>
/* eslint-disable */
import {
  NLayout,
  NLayoutContent,
  NFormItem,
  NInput,
  NRadioGroup,
  NForm,
  NRadio,
  NSpace,
} from "naive-ui";

export default {
  components: {
    NRadio,
    NRadioGroup,
    NLayout,
    NLayoutContent,
    NSpace,
    NForm,
    NInput,
    NFormItem,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    handleChange(e) {
      this.value = e.target.value;
      this.$emit("update:modelValue", e.target.value);
      this.$emit("change", e.target.value);
    },
    updateValue(event) {},
  },
  watch: {
    modelValue(newV) {
      this.value = newV;
    },
  },
  props: {
    modelValue: {
      type: String,
    },
    list: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      type: String,
    },
    name: {
      type: String,
    },
    path: {
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
<style>
.n-form-item-label {
  box-sizing: border-box;
  color: rgb(31, 34, 37);
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  grid-column-end: label;
  grid-column-start: label;
  grid-row-end: label;
  grid-row-start: label;
  height: 1px;
  justify-content: flex-start;
  line-height: 55.5px;
  text-align: left;
  margin-top: 25px;
}

.input-form {
  padding-top: 1px;
  padding-bottom: 20px;
}
.description {
  text-align: left;
  font-size: 10px;
}

.n-form-item-label__asterisk {
  color: rgb(47, 48, 137);
  text-align: left;
}
</style>
