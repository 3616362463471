<template></template>

<!-- <span>
    Расчётная цена <strong>{{price}}</strong> р.
  </span> -->

<script>
export default {
  props: {
    price: {
      required: true,
      type: Number,
    },
  },
};
</script>
<style></style>
