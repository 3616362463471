<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-theme-editor>
      <n-layout
        ><!---->
        <img
          src="@/assets/clubTour.jpg"
          class="w-full max-w-screen-lg mx-auto h-auto mb-15"
        />
        <div
          class="border border-slate-200 flex justify-center items-center p-0 m-0 text-custom-purple font-bold -mb-4 mt-6 text-xl"
        >
          Предрегистрация
        </div>
        <div class="FormClass left-align">
          <NForm ref="formRef" :rules="rules" :model="formdata">
            <SelectList
              :title="form.attendies.title"
              ref="element_attendies"
              name="attend_cnt"
              :list="form.attendies.list"
              v-model="formdata.form.attendies"
              path="form.attendies"
              :required="true"
              v-on:change="
                valid(this, $event);
                changeAttendies(this, $event);
              "
            ></SelectList>
            <SelectList
              :title="form.livevars.title"
              name="live_vars"
              :list="getLiveVarsList"
              v-model="formdata.form.livevars"
              ref="element_livevars"
              path="form.livevars"
              :required="true"
              v-on:change="
                valid(this, $event);
                changeLiveVars();
              "
            ></SelectList>

            <SelectList
              class="-mt-1"
              title="Утренние варианты активностей"
              name="morning_activities_name"
              :list="form.morning_activities_array.list"
              ref="element_morning_activities"
              v-model="formdata.form.morning_activities"
              path="form.morning_activities"
              :required="true"
              v-on:change="valid(this, $event)"
            >
            </SelectList>

            <!-- <h3
              class="border border-slate-200 rounded-xl flex justify-center items-center p-0 m-0 text-custom-purple font-bold mt-5"
            >
              Программа для первого участника
            </h3>
            <SelectList
              title="Первый день (до обеда)"
              name="first_day_first"
              :list="form.first_day.list"
              ref="element_first_day_1"
              v-model="formdata.form.first_day_1"
              path="form.first_day_1"
              :required="true"
              v-on:change="valid(this, $event)"
            ></SelectList>
            <SelectList
              title="Второй день (до обеда)"
              name="second_day_first"
              :list="form.second_day.list"
              ref="element_second_day_1"
              v-model="formdata.form.second_day_1"
              path="form.second_day_1"
              :required="true"
              v-on:change="valid(this, $event)"
            ></SelectList>
            <SelectList
              title="Третий день (до обеда)"
              name="third_day_first"
              :list="form.third_day.list"
              ref="element_third_day_1"
              v-model="formdata.form.third_day_1"
              path="form.third_day_1"
              :required="true"
              v-on:change="valid(this, $event)"
            ></SelectList>
            <span v-if="hasSecond">
              <h3
                class="border border-slate-200 rounded-xl flex justify-center items-center p-0 m-0 text-custom-purple font-bold mt-5"
              >
                Программа для второго участника
              </h3>
              <SelectList
                title="Первый день (до обеда)"
                name="first_day_second"
                :list="form.first_day.list"
                ref="element_first_day_2"
                v-model="formdata.form.first_day_2"
                path="form.first_day_2"
                :required="true"
                v-on:change="valid(this, $event)"
              ></SelectList>
              <SelectList
                title="Второй день (до обеда)"
                name="second_day_second"
                :list="form.second_day.list"
                ref="element_second_day_2"
                v-model="formdata.form.second_day_2"
                path="form.second_day_2"
                :required="true"
                v-on:change="valid(this, $event)"
              ></SelectList>
              <SelectList
                title="Третий день (до обеда)"
                name="third_day_second"
                :list="form.third_day.list"
                ref="element_third_day_2"
                v-model="formdata.form.third_day_2"
                path="form.third_day_2"
                :required="true"
                v-on:change="valid(this, $event)"
              ></SelectList>
            </span>
            <SelectList
              :title="form.planetickets.title"
              ref="element_planetickets"
              name="planetickets"
              :list="form.planetickets.list"
              :description="form.planetickets.description"
              path="form.planetickets"
              :required="true"
              v-on:change="valid(this, $event)"
              v-model="formdata.form.planetickets"
            ></SelectList>
            <template v-if="formdata.form.planetickets > 0">
              <NFormItem
                label="Город прилета"
                ref="element_from_city"
                path="form.from_city"
              >
                <n-input
                  placeholder="Город, куда Вы улетите после конференции"
                  :required="true"
                  @keyup="valid(this, $event)"
                  v-on:change="valid(this, $event)"
                  v-on:input="valid(this.$event)"
                  v-model:value="formdata.form.from_city"
                ></n-input>
              </NFormItem>
              <NFormItem
                label="Город отправления"
                path="form.to_city"
                ref="element_to_city"
              >
                <n-input
                  placeholder="Город, откуда Вы прилетаете на конференцию"
                  :required="true"
                  @keyup="valid(this, $event)"
                  v-on:change="valid(this, $event)"
                  v-on:input="valid(this.$event)"
                  v-model:value="formdata.form.to_city"
                ></n-input>
              </NFormItem> -->
            <!-- </template> -->
            <div class="textblock mt-5">
              <span class="font-bold text-custom-purple"
                >С участием в конференции вы получите: </span
              ><br />
              <ul>
                <li>• 3-х дневная деловая и развлекательная программы.</li>
                <li>• Трансфер на автобусе до отеля и обратно.</li>
                <li>
                  • Завтраки, обеды и вечерние мероприятия на весь период
                  проживания.
                </li>
              </ul>
            </div>

            <!-- <h3
              class="border border-slate-200 rounded-xl flex justify-center items-center p-0 m-0 text-custom-purple font-bold mt-5 mb-3"
            >
              СКИДКИ
            </h3>

            <div>
              Для получения скидки в 1000 руб /Приведи друга/ напишите,
              пожалуйста, ФИО и организацию того, кого вы порекомендовали или
              кто порекомендовал вас. Скидка действительна после регистрации
              друга.
            </div> 

            <NFormItem class="-mb-14">
              <n-input
                placeholder="ФИО и Организация друга"
                type="textarea"
                v-model:value="formdata.user.friend_info"
              />
            </NFormItem>
            --
            <NFormItem>
              <CalcPrice :price="calc_price"></CalcPrice>
            </NFormItem>
-->

            <!-- Пошло ФИО -->
            <NFormItem
              label="ФИО первого участника"
              ref="element_name"
              path="user.name"
            >
              <n-input
                placeholder="Ваше ФИО"
                v-model:value="formdata.user.name"
              />
            </NFormItem>
            <NFormItem
              :label="getFirstPhoneLabel"
              ref="element_phone"
              path="user.phone"
            >
              <n-input
                placeholder="xxx-xxx-xxxx"
                v-model:value="formdata.user.phone"
                @keyup="valid(this, $event)"
              >
                <template #prefix> +7 </template>
              </n-input>
            </NFormItem>
            <NFormItem label="E-mail" path="user.mail" ref="element_mail">
              <n-input
                placeholder="E-mail"
                v-model:value="formdata.user.mail"
                @keyup="valid(this, $event)"
              >
              </n-input>
            </NFormItem>
            <NFormItem
              label="Ваша компания"
              ref="element_company"
              path="user.company"
            >
              <n-input
                placeholder="Компания"
                v-model:value="formdata.user.company"
              />
            </NFormItem>
            <NFormItem label="Регион" path="user.region" ref="element_region">
              <n-input
                placeholder="Регион"
                v-model:value="formdata.user.region"
              />
            </NFormItem>

            <NFormItem label="ИНН" path="user.inn" ref="element_inn">
              <n-input
                placeholder="ИНН"
                v-model:value="formdata.user.inn"
                @keyup="valid(this, $event)"
              >
              </n-input>
            </NFormItem>

            <template v-if="formdata.form.attendies == 2">
              <NFormItem
                label="ФИО второго участника"
                ref="element_second_info"
                path="user.second_info"
              >
                <n-input
                  placeholder="Ваше ФИО"
                  v-model:value="formdata.user.second_info"
                />
              </NFormItem>
            </template>
            <template v-if="formdata.form.attendies == 2">
              <NFormItem
                label="Мобильный телефон второго участника"
                ref="element_phone2"
                path="user.phone2"
              >
                <n-input
                  placeholder="xxx-xxx-xxxx"
                  v-model:value="formdata.user.phone2"
                  @keyup="valid(this, $event)"
                >
                  <template #prefix> +7 </template>
                </n-input>
              </NFormItem>
            </template>

            <!--    <NFormItem label="" path="user.second_info">

        <template #prefix>
          <n-icon :component="AlternateEmailFilled"></n-icon>
        </template>
    </NFormItem>-->

            <div
              class="border border-slate-200 rounded-xl flex justify-center items-center p-0 m-0 text-custom-purple font-bold -mb-3 mt-2"
            >
              Пожелания
            </div>

            <NFormItem label="Пожелания" path="user.wishes">
              <n-input
                placeholder=""
                type="textarea"
                v-model:value="formdata.user.wishes"
              />
            </NFormItem>

            <div class="flex justify-center">
              <input type="radio" name="radioSponsor" />
              <span class="mt-1 ml-1.5"
                >Хочу использовать
                <span class="text-custom-purple font-bold"
                  >дополнительные возможности продвижения и рекламы </span
                >на площадках конференции.</span
              >
            </div>

            <div class="mt-4 text-slate-700">
              За
              <span class="text-custom-purple font-bold"
                >раннюю регистрацию </span
              >подарим вам скидку на будущую поездку 3000 рублей.
            </div>

            <NButton
              @click="register"
              data-submit-button="true"
              :style="getRegButtonStyle"
              >Регистрация (dev)</NButton
            >
            <span :style="getRegButtonStyle">{{ devel_data.response }}</span>
            <template v-if="isDev"> </template>

            <div style="margin-bottom: 100px"></div>
          </NForm>

          <NLayoutFooter
            style="
              height: 40px;
              position: fixed;
              z-index: 10000;
              bottom: 0px;
              width: 100%;
              left: 0px;
            "
            bordered
          >
            <div
              style="
                display: block;
                /* vertical-align: middle; */
                text-align: center;
                line-height: 40px;
              "
            >
              <CalcPrice :price="calc_price"></CalcPrice>
            </div>
          </NLayoutFooter>
        </div>
      </n-layout>
    </n-theme-editor>
  </n-config-provider>
</template>

<script>
let CONF_DATE_FIXED = new Date(2024, 1, 19);
const MSG_REQUIRED = (
  <span class="text-[#2f3089]"> Обязательно для заполнения </span>
);
const re_mail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

//const ;
const axios = require("axios").default;
import CalcPrice from "@/components/CalcPrice";
import SelectList from "@/components/SelectList";
import {
  NFormItem,
  NButton,
  NInput,
  NForm,
  NLayout,
  NLayoutFooter,
} from "naive-ui";
import { computed } from "vue";
//import { defineComponent, ref } from "vue";
//import {
//  useMessage
//} from "naive-ui";
//const message = useMessage();

import { NConfigProvider } from "naive-ui";
import { NThemeEditor } from "naive-ui";

// @type import('naive-ui').GlobalThemeOverrides
const themeOverrides = {
  common: {
    successColor: "#1518BAFF",
    successColorHover: "#1518BAFF",
    successColorPressed: "#1518BAFF",
    successColorSuppl: "#1518BAFF",
    errorColor: "#1d1e56FF",
    errorColorHover: "#1d1e56FF",
    errorColorPressed: "#1d1e56FF",
    errorColorSuppl: "#1d1e56FF",
  },
};

export default {
  components: {
    NConfigProvider,
    // NThemeEditor,
    SelectList,
    NForm,
    NButton,
    NInput,
    CalcPrice,
    NFormItem,

    NLayout,
    NLayoutFooter,
  },
  data() {
    return {
      themeOverrides: themeOverrides,
      devel_data: {
        response: "",
      },
      start_time: new Date(),
      conf_date_tz: CONF_DATE_FIXED,
      initDataUnsafe: {},
      initData: {},
      formdata: {
        form: {
          attendies: null,
          livevars: null,
          skipass1: null,
          skipass2: null,
          planetickets: null,
          morning_activities: null,
          first_day_1: null,
          first_day_2: null,
          second_day_1: null,
          second_day_2: null,
          third_day_1: null,
          third_day_2: null,
          from_city: null,
          to_city: null,
        },
        user: {
          phone2: null,
          phone: null,
          company: null,
          name: null,
          friend_info: null,
          mail: null,
          region: null,
          second_info: null,
          wishes: null,
          inn: null,
          morning_activities: null,
        },
      },
      day_length: 3,
      add_prices: {
        lunches: 750,
        transfer: 2000,
        base_price: 34250,
      },
      add_data: "",
      form: {
        attendies: {
          title: "Выберите количество участников",
          list: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }],
          value: null,
        },
        livevars: {
          title: "Варианты размещения",
          list: [
            {
              description: "Одноместное проживание",
              value: "standart22-1",
              count: 1,
              price: 0, //price: 6800,
            },
            {
              description: "Двухместное проживание",
              value: "standart22-2",
              count: 2,
              price: 0, //price: 4950,
            },
            // {
            //   description: "Одноместное проживание Студия (26 м)",
            //   value: "deluxe34-1",
            //   count: 1,
            //   price: 0, //price: 8100,
            // },
            // {
            //   description: "Двухместное проживание Студия (26 м)",
            //   value: "deluxe34-2",
            //   count: 2,
            //   price: 0, //price: 5600,
            // },
          ],
          value: null,
        },
        skipass1: {
          title: "Количество скипассов на первого участника ",
          description:
            "Выберите количество дней катания, из расчета 4 часа в день",
          list: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
          price: 0, //price: 2000,
          value: null,
        },
        skipass2: {
          title: "Количество скипассов на второго участника ",
          description:
            "Выберите количество дней катания, из расчета 4 часа в день",
          list: [
            { value: 0, price: 0 },
            { value: 1 },
            { value: 2 },
            { value: 3 },
          ],
          price: 0, //price: 2000,
          value: null,
        },
        planetickets: {
          title: "Билеты на самолет",
          description: "Количество билетов на самолёт туда-обратно",
          list: [{ value: 0 }, { value: 1 }, { value: 2 }],
          price: 0,
          value: null,
        },
        exc: {
          price: 3000,
        },
        morning_activities_array: {
          title: "Утренние активности",
          list: [
            {
              description: "Катаюсь на горных лыжах / сноуборде",
              value: "morning_skating",
            },
            {
              description: "Хочу научиться кататься",
              value: "morning_want_to_teach",
            },
            {
              description: "Я больше по экскурсиям",
              value: "morning_excursion",
            },
            { description: "Утром надо спать", value: "morning_sleep" },
          ],
        },
        first_day: {
          title: "Первый день",
          list: [
            { description: "Ничего", value: "day1_none" },
            { description: "Скипасс", value: "day1_skipass" },
            { description: "Экскурсии", value: "day1_exc" },
          ],
        },
        second_day: {
          title: "Второй день",
          list: [
            { description: "Ничего", value: "day2_none" },
            { description: "Скипасс", value: "day2_skipass" },
            { description: "Экскурсии", value: "day2_exc" },
          ],
        },
        third_day: {
          title: "Третий день",
          list: [
            { description: "Ничего", value: "day3_none" },
            { description: "Скипасс", value: "day3_skipass" },
            { description: "Соревнования", value: "day3_comp" },
          ],
        },
        morning_activities: {
          title: "Утренние варианты активностей",
          value: null,
        },
        first_day_1: {
          title: "Первый день (1 участник)",
          value: null,
        },
        first_day_2: {
          title: "Первый день (2 участник)",
          value: null,
        },
        second_day_1: {
          title: "Второй день (1 участник)",
          value: null,
        },
        second_day_2: {
          title: "Второй  день (2 участник)",
          value: null,
        },
        third_day_1: {
          title: "Третий  день (1 участник)",
          value: null,
        },
        third_day_2: {
          title: "Третий  день (2 участник)",
          value: null,
        },
        from_city: {
          title: "Город отправления",
          value: null,
        },
        to_city: {
          title: "Город прибытия",
          value: null,
        },
      },
    };
  },
  computed: {
    getLiveVarsList() {
      /*if (!this.formdata.form.attendies)
      {
        return this.form.livevars.list;
      }
      else
      {
        return this.form.livevars.list.filter(item=>item.count==this.formdata.form.attendies);
      }*/
      return this.form.livevars.list;
    },
    discountOne() {
      return this.formdata.form.attendies == 2 &&
        (this.formdata.form.livevars == "standart22-2" ||
          this.formdata.form.livevars == "deluxe34-2")
        ? 3000
        : 0;
    },
    getFirstDayPrice() {
      const p1 = this.formdata.first_day_1;
      const p2 = this.formdata.first_day_2;

      return this.dayPriceCalc(p1, p2, this.formdata.attendies);
    },
    getSecondDayPrice() {
      const p1 = this.formdata.second_day_1;
      const p2 = this.formdata.second_day_2;

      return this.dayPriceCalc(p1, p2, this.formdata.attendies);
    },
    getThirdDayPrice() {
      const p1 = this.formdata.third_day_1;
      const p2 = this.formdata.third_day_2;

      return this.dayPriceCalc(p1, p2, this.formdata.attendies);
    },
    getDateDiff() {
      const diff = this.conf_date_tz - this.start_time;
      return diff > 0 ? Math.floor(diff / (1000 * 60 * 60 * 24)) : 0;
    },
    discountOfWeek() {
      return (
        (Math.floor(this.getDateDiff / 7) - 1) *
        100 *
        (this.formdata.form.attendies > 0 ? 1 : 0)
      );
    },
    getFirstPhoneLabel() {
      return this.formdata.form.attendies == 2
        ? "Мобильный телефон первого участника"
        : "Мобильный телефон";
    },
    hasSecond() {
      return this.formdata.form.attendies == 2;
    },
    getAddData() {
      return this.add_data;
    },
    getRegButtonStyle() {
      if (!this.isDev) {
        return "display:none;";
      }
      return "";
    },
    attendies() {
      return this.formdata.form.attendies !== null
        ? parseInt(this.formdata.form.attendies)
        : 0;
    },
    calc_base_prices() {
      //return (this.add_prices.base_price+this.add_prices.lunches*this.day_length);
      return 34250;
    },
    calc_first_day_1() {
      return this.switch_days(this.formdata.form.first_day_1);
    },
    calc_second_day_1() {
      return this.switch_days(this.formdata.form.second_day_1);
    },
    calc_third_day_1() {
      return this.switch_days(this.formdata.form.third_day_1);
    },
    calc_first_day_2() {
      return this.switch_days(this.formdata.form.first_day_2);
    },
    calc_second_day_2() {
      return this.switch_days(this.formdata.form.second_day_2);
    },
    calc_third_day_2() {
      return this.switch_days(this.formdata.form.third_day_2);
    },
    calc_per_day_1() {
      return this.formdata.form.attendies > 0
        ? this.calc_first_day_1 + this.calc_second_day_1 + this.calc_third_day_1
        : 0;
    },
    calc_per_day_2() {
      return this.formdata.form.attendies == 2
        ? this.calc_first_day_2 + this.calc_second_day_2 + this.calc_third_day_2
        : 0;
    },
    calc_price() {
      //(Проживание+_обеды+трансфер+Экскурсии+Билеты на самолет+программа)*кол-во+число подемов*10+3000
      //skipass=1800
      //

      return (
        (this.calc_base_prices + this.calc_livevars * this.day_length) *
          this.attendies +
        this.calc_plane +
        this.calc_per_day_1 +
        this.calc_per_day_2 -
        (this.discountOne + this.discountOfWeek)
      );
    },
    calc_plane() {
      return this.formdata.form.planetickets * this.form.planetickets.price;
    },
    calc_skipass1() {
      return this.formdata.form.skipass1 !== null
        ? this.formdata.form.skipass1 * this.form.skipass1.price
        : 0;
    },
    calc_skipass2() {
      return this.formdata.form.skipass2 !== null
        ? this.formdata.form.skipass2 * this.form.skipass2.price
        : 0;
    },
    calc_skipasses() {
      return this.calc_skipass1 + this.calc_skipass2;
    },
    calc_livevars() {
      if (!this.form.livevars.list) {
        return 0;
      }
      return this.formdata.form.livevars !== null &&
        this.formdata.form.livevars !== false
        ? this.form.livevars.list.find(
            (item) => item.value === this.formdata.form.livevars
          ).price
        : 0;
    },
    isDev() {
      //      console.log(process.env.NODE_ENV=='development');
      return process.env.NODE_ENV == "development";
    },
    rules() {
      return {
        form: {
          morning_activities: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          first_day_1: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          first_day_2: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          second_day_1: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          second_day_2: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          third_day_1: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          third_day_2: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          attendies: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },

          livevars: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          skipass1: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          skipass2: {
            required: true,
            trigger: ["change"],
            message: MSG_REQUIRED,
          },
          planetickets: {
            required: true,
            trigger: ["change", "blur"],
            message: MSG_REQUIRED,
          },
          from_city: {
            required: true,
            trigger: ["input", "blur", "change"],
            message: MSG_REQUIRED,
          },
          to_city: {
            required: true,
            trigger: ["input", "blur", "change"],
            message: MSG_REQUIRED,
          },
        },
        user: {
          region: {
            required: true,
            trigger: ["input", "blur"],
            message: MSG_REQUIRED,
          },
          company: {
            required: true,
            trigger: ["input", "blur"],
            message: MSG_REQUIRED,
          },
          name: {
            required: true,
            trigger: ["input", "blur"],
            message: MSG_REQUIRED,
          },
          second_info: {
            required: true,
            trigger: ["input", "blur"],
            message: MSG_REQUIRED,
          },
          phone2: {
            required: true,
            message: MSG_REQUIRED,
            validator(rule, value) {
              if (!value) return false;
              const trim_val = value.trim();
              if (
                !/^[0-9]{10}$/.test(trim_val) &&
                !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(trim_val) &&
                !/^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(trim_val)
              )
                return new Error("Неправильный номер");
              return true;
            },
            trigger: ["input", "blur"],
          },
          phone: {
            required: true,
            message: MSG_REQUIRED,
            validator(rule, value) {
              if (!value) return false;
              const trim_val = value.trim();
              if (
                !/^[0-9]{10}$/.test(trim_val) &&
                !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(trim_val) &&
                !/^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(trim_val)
              )
                return new Error("Неправильный номер");
              return true;
            },
            trigger: ["input", "blur"],
          },
          mail: {
            required: true,
            message: MSG_REQUIRED,
            trigger: ["input", "blur"],
          },
        },
      };
    },
  },
  mounted() {
    this.valid();
    this.initData = window.Telegram.WebApp.initData;
    this.initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

    const urlParams = new URL(document.location).searchParams; //new URLSearchParams(queryString);
    console.log(urlParams.get("start_app"));
    this.conf_date_tz = new Date(
      this.conf_date_tz.getTime() + 3 * 60 * 60 * 1000
    );
    this.add_data =
      urlParams.get("start_app") ??
      urlParams.get("startapp") ??
      urlParams.get("startApp") ??
      urlParams.get("tgWebAppStartParam") ??
      undefined;
  },
  watch: {},
  methods: {
    toggleRadio(value) {
      if (this.selected === value) {
        this.selected = null; // Отменяем выбор, если выбран тот же радиобаттон
      }
    },
    dayPriceCalc(p1, p2, attendies) {
      let res_price = [0, 0];
      const price_arr = [p1, p2];

      price_arr.forEach((price_type, i) => {
        switch (price_type) {
          case "day1_none":
          case "day2_none":
          case "day3_none":
            res_price[i] = 0;
            break;
          case "day1_exc":
          case "day2_exc":
          case "day3_exc":
            res_price[i] = 0; //res_price[i] = 3000;
            break;
          case "day1_skipass":
          case "day2_skipass":
          case "day3_skipass":
          case "day3_comp":
            res_price[i] = 0; //res_price[i] = 2000;
            break;
        }
      });
      switch (attendies) {
        case 0:
          return 0;
        case 1:
          return res_price[0];
        case 2:
          return res_price[0] + res_price[1];
      }
    },
    changeAttendies(obj, event) {
      /*if (this.form.livevars.list)
          if (!this.form.livevars.list.find(lv=>(lv.value===this.formdata.form.livevars && parseInt(lv.count)===parseInt(event))))
            this.formdata.form.livevars=null;*/
    },
    changeLiveVars() {},
    processResponse(response) {
      if ((!this.isDev && response.data.status == "ok") || this.isDev) {
        this.$emit("onSuccess", {
          ...response,
          final_discount: this.discountOne + this.discountOfWeek,
          week_discount: this.discountOfWeek,
        });
      } else {
        this.$emit("onError", response);
      }
    },
    switch_days(val) {
      switch (val) {
        case "day1_none":
        case "day2_none":
        case "day3_none":
          return 0.0;
        case "day1_skipass":
        case "day2_skipass":
        case "day3_skipass":
        case "day3_comp":
          return this.form.skipass1.price;
        case "day1_exc":
        case "day2_exc":
          return this.form.exc.price;
        case null:
          return 0.0;
      }
      return 0.0;
    },
    valid() {
      this.$refs.formRef.validate((errors) => {
        if (!errors) {
          //this.setRegButton(true);

          return [];
        } else {
          // this.setRegButton(false);

          return errors;
        }
      });
    },
    scrollElement(item) {
      const field = item[0].field;
      //const left = this.$refs.busstop.getBoundingClientRect().left
      //const top = this.$refs.busstop.getBoundingClientRect().top
      let needScroll = false;
      let obj = null;
      //console.log("field"+field);
      switch (field) {
        case "user.phone":
          obj = this.$refs.element_phone.$el;
          needScroll = true;
          break;
        case "user.phone2":
          obj = this.$refs.element_phone2.$el;
          needScroll = true;
          break;
        case "user.mail":
          obj = this.$refs.element_mail.$el;
          needScroll = true;
          break;
        case "user.name":
          obj = this.$refs.element_name.$el;
          needScroll = true;
          break;
        case "user.second_info":
          obj = this.$refs.element_second_info.$el;
          needScroll = true;
          break;
        case "user.region":
          obj = this.$refs.element_region.$el;
          needScroll = true;
          break;
        case "user.company":
          obj = this.$refs.element_company.$el;
          needScroll = true;
          break;
        case "form.attendies":
          obj = this.$refs.element_attendies.$el;
          needScroll = true;
          break;
        case "form.livevars":
          obj = this.$refs.element_livevars.$el;
          needScroll = true;
          break;
        case "form.planetickets":
          obj = this.$refs.element_planetickets.$el;
          needScroll = true;
          break;
        case "form.skipass1":
          obj = this.$refs.element_skipass1.$el;
          needScroll = true;
          break;
        case "form.skipass2":
          obj = this.$refs.element_skipass2.$el;
          needScroll = true;
          break;
        case "form.morning_activities":
          obj = this.$refs.element_morning_activities.$el;
          needScroll = true;
          break;
        case "form.first_day_1":
          obj = this.$refs.element_first_day_1.$el;
          needScroll = true;
          break;
        case "form.second_day_1":
          obj = this.$refs.element_second_day_1.$el;
          needScroll = true;
          break;
        case "form.third_day_1":
          obj = this.$refs.element_third_day_1.$el;
          needScroll = true;
          break;
        case "form.first_day_2":
          obj = this.$refs.element_first_day_2.$el;
          needScroll = true;
          break;
        case "form.second_day_2":
          obj = this.$refs.element_second_day_2.$el;
          needScroll = true;
          break;
        case "form.third_day_2":
          obj = this.$refs.element_third_day_2.$el;
          needScroll = true;
          break;
        case "form.from_city":
          obj = this.$refs.element_from_city.$el;
          needScroll = true;
          break;
        case "form.to_city":
          obj = this.$refs.element_to_city.$el;
          needScroll = true;
          break;
      }

      if (needScroll && obj) {
        const top = obj.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: top, behavior: "smooth" });
      }
    },
    setRegButton(status) {
      if (status) {
        //        window.Telegram.WebApp.MainButton.enable();
        //        window.Telegram.WebApp.MainButton.text="Зарегистрировать";
        //window.Telegram.WebApp.MainButton.setParams({color:"#CFCFCF"});
      } else {
        //        window.Telegram.WebApp.MainButton.disable();
        //        window.Telegram.WebApp.MainButton.text="Зарегистрировать";
        //window.Telegram.WebApp.MainButton.setParams({color:"var(--tg-theme-button-color)"});
      }
    },
    register() {
      //Здесь запрос на сервер
      this.$refs.formRef.validate((errors) => {
        if (errors) {
          this.scrollElement(errors[0]);
        } else {
          console.log("Sending to server");
          const url =
            process.env.VUE_APP_REQUEST_URL + process.env.VUE_APP_REQUEST_PATH;
          console.log(url);
          axios
            .post(url, {
              initData: this.initData,
              initDataUnsafe: this.initDataUnsafe,
              user: this.formdata.user,
              form: this.formdata.form,
              reginfo: this.form,
              price: this.calc_price,
              add_data: this.getAddData,
              final_discount: this.discountOne + this.discountOfWeek,
              week_discount: this.discountOfWeek,
              morning_activities: this.morning_activities,
            })
            .then((response) => this.processResponse(response))
            .catch((error) => this.$emit("onError", error));
        }
        //this.devel_data.response=response
      });
    },
  },
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
  },
};
window.register = function (vuecomponent) {
  vuecomponent.register();
};
</script>

<style>
.FormClass {
  padding: 20px;
}
.textblock {
  text-align: left;
}
.left-align {
  text-align: left;
}
.border {
  padding-bottom: 20px;
}
.description {
  margin-top: 1rem;
  text-align: left;
  display: block;
  font-size: 10px;
}
</style>
